import '../App.css';
import SideNote from "./SideNote";

interface simpleListProps {
  listItems: string[]
}


const SimpleList: React.FC<simpleListProps> = ({ listItems }) => {
  return (
    <ul className="arrow-list">
      {listItems.map((item, index) => (
        <li key={index} className="arrow-list-item">
          <span className="arrow-list-arrow essay-text">➔</span>
          <span>
            <span className="essay-text" dangerouslySetInnerHTML={{ __html: item }}></span>
            {/* Janky way to add a one-off side note to bullet point */}
            {/* {item.includes('Overturning Elections') &&
              <span className='info-button'>
                <SideNote note={latopMisinformationSideNote} isCaption={false} />
              </span>
            } */}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default SimpleList;