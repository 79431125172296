import { useEffect } from 'react';
import { Grid } from "@mui/material";
import SimpleList from "../components/SimpleList";
import '../App.css';

// Images
import howItStarted from '../images/Mindfulness/how-it-started.png';
import howItsGoing from '../images/Mindfulness/how-its-going.png';
import mindfulnessConvertible from '../images/Mindfulness/mindfulness-hype-convertible.png';

const reflectionSteps = [
  `What you did well should only include the skills/habits/behaviors/etc. that you’re working on, instead of the ones that already come naturally to you – for example, in the beginning I would make a note of remembering to leave my phone out of reach while working, but eventually that became automatic and wasn’t worth writing down.`,
  `For what you should have done better, if you’re making the same mistake over and over again (which you will do, because we all do that, because behavior change is slow and difficult), it’s important to write it down again and again. You’ll only be hurting yourself if you pretend you're not repeating the mistake. There are still mistakes I’ve repeated on and off for the last two years.`,
  `What you learned might include a new way to think about something, or a new approach to a task, or something to try in the future to address a problem from that day. The easiest way to find learnings is by looking at what you did well and checking if there’s a particular reason you did that well when you hadn’t in the past, or to look at what you did poorly and think about what to do differently to avoid the mistake in the future. Try to find as many learnings as you can for each day, instead of stopping when you come up with the first one, although I only had one on most days. Unlike the “good” and “bad” entries, you shouldn’t repeat a previous day’s entry in the “learned” category, because by definition learnings are new to you. Ideally these are learnings that you come up with yourself, but that doesn’t have to be the case. These learnings also don’t have to be profound, life-changing epiphanies, and could instead include interesting facts that might not relate to you at all (I know I had at least one learning about dogs). As with anything else, you get out what you put in. Forcing myself to always come up with my own learning made me spend arguably too much time on the entries some days, but that likely contributed to how much this helped me grow. I probably would’ve seen even more growth if I spent even longer.`,
  `Add new entries to the top of the document instead of the bottom, so you don’t have to scroll for pages and pages as the year goes on.`,
  `An optional addition that I highly recommend is writing down any notable events that you want to remember. It makes it easy and fun to look back on the year and remember important moments. You won’t have a “notable” category for every day’s entry.`
]

export const Mindfulness = ({}) => {
  useEffect(() => {
    document.title = 'Making Mindfulness Your Default';
  }, []);
  return (
    <Grid className="centered-content">
      <div className="container">
        <p className="essay-title">
          Making Mindfulness Your Default
        </p>
        <p className="essay-date">
          1/1/21
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            On January 1, 2019 I started looking back on my day and recording what I did well, what I did poorly, and what I learned. Steady, incremental progress from two years of this ritual has made such a difference that I barely recognize myself in some of the early entries; certain entries from six months ago even seem like they’re from a completely different person.
          </span>
        </p>
        <div className="image-container">
          <img src={howItStarted} className="essay-image-large" />  
          <p className="image-caption">
            How it started
          </p>
        </div>
        <div className="image-container">
          <img src={howItsGoing} className="essay-image-large" />  
          <p className="image-caption">
            How it’s going
          </p>
        </div>
        <p className="essay-paragraph">
          <span className="essay-text">
            Mindfulness is usually viewed as the exception. Not only is it only for specific people (hippies), it’s something those people only use in certain situations – for meditation or to handle stress, for example. Mindfulness should instead be our default. This isn’t a new idea: Buddhism centers around mindfulness, Daniel Kahneman summarized decades of his pioneering work in behavioral economics with <i>Thinking, Fast and Slow</i>, and David Foster Wallace argues for the importance of day-to-day mindfulness in his <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=8CrOL-ydFMI">“This is Water”</a> commencement speech. In meditation you use mindfulness to observe your thoughts and let them come and go, whereas outside of meditation you can observe your thoughts to react to them more intentionally. It allows us to decide what we truly want to do instead of what our instincts or habits are pulling us to do, and to think about the best approach for what we’re doing.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Most of my learnings from the past two years rely on mindfulness in some way. Before starting a task, I step back and ask myself if there’s a more efficient way to do the work, or if it’s a low-value item I should rush through as quickly as possible, or if it’s truly a priority, or if it’s something I even need to do at all. (Not asking these and other questions feels like the productivity equivalent of trying to run with my shoes tied together...but that’s how I’ve been working my whole professional life.) Before starting a break, I think ahead to the tasks I’ll do after the break, to make it easier to transition back to work. When I’m scrolling through Twitter, I check in with myself every once in a while to make sure I actually want to keep scrolling, instead of continuing because of social media’s stranglehold on my brain. In a conversation, I think about the motivation for whatever the other person is saying and where it’s coming from emotionally. If I’m frustrated with a friend, I think about all the small times they’ve immediately forgiven me for being dumb. When I’m beating myself up about a mistake, I remember that it’s important to forgive myself just like I’d forgive a friend. I’m not able to do these all consistently (especially the Twitter one), but that’s what I’m working towards. “Mindfulness”, “thinking slow”, “stepping back”, whatever you want to call it, the point is that you’re putting in the effort to use a higher level of thinking. You’re not settling for your automatic response. All of us have a better version of ourselves hidden behind our automatic responses, and the way to get to that better version is through mindfulness.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            These daily reflections are the best way I know to gradually increase how mindful you are throughout the day. Reflecting every night means you’re practicing mindfulness daily. Eventually I realized I should also check in with myself halfway through the day, and then soon after I realized I should think about how the day’s going as much as possible. Having to write down your mistakes also makes it easier to start noticing them in the moment. It strikes me as similar to how logging what you eat <a target="_blank" rel="noopener noreferrer" href="https://www.health.harvard.edu/blog/why-keep-a-food-diary-2019013115855">helps you lose weight</a> – you’re forced to be more aware of what you’re doing, which allows you to actively think through choices that would’ve been automatic. After three days in a row of writing down, “I should have shortened my breaks”, I’m much more likely to remember to keep my breaks short. Before I started this journaling, I might repeat the same bad habit for days or weeks and barely notice.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Mindfulness is so central to these reflections because applying the learnings – instead of just patting myself on the back for coming up with them – takes routine mindfulness. To change my behavior, I have to notice when I’m falling into the same traps I documented in previous days, snap out of it, think about what I learned, and apply that. Mindfulness enables this. My learning for April 17, 2019 was “It’s not that difficult to push myself to do a tiny bit better in anything I want to improve – the hard part is remembering to push myself...but remembering to do that is also a skill that can be improved.” I hadn’t yet boarded the mindfulness hype train, but that learning can be seen as my first step on the path to it. A key barrier to growth is failing to notice in the moment that we should change our behavior. Mindfulness, then, is like steroids for personal growth. If we become better at being mindful in our daily lives, it will be easier for us to improve in all other areas. And without explicitly aiming for it, simply from doing these daily reflections and trying to implement what I learn, one of my biggest areas of growth has been my ability to be mindful throughout the day.
          </span>
        </p>
        <div className="image-container">
          <img src={mindfulnessConvertible} className="essay-image-large" />  
          <p className="image-caption">
            The mindfulness hype <s>train</s> convertible
          </p>
        </div>
        <p className="essay-paragraph">
          <span className="essay-text">
            Go through each part of your day three times, looking for what you did well, what you should have done better, and what you learned.
          </span>
        </p>
        <p className="essay-paragraph">
          <SimpleList listItems={reflectionSteps}/>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Use this framework as a starting point, but play around with it to find what’s right for you. You can write whole sentences or fragments, spend thirty minutes on it or three, and add categories or remove them. The point is to wring more out of each day, day after day, which you can only do if you enjoy the process. If you adapt the template, please let me know! I’m curious to hear what you come up with.
          </span>
        </p>
      </div>
    </Grid>
  )
}
