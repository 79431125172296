import { Grid } from "@mui/material";
import SideNote from "../components/SideNote";
import SimpleList from "../components/SimpleList";
import '../App.css';

// Images
import wireCoil from '../images/PublicHealth/wire-coil.png';
import frayedTangles from '../images/PublicHealth/frayed-tangles.jpg';

const pmfDefinitionSideNote = `<span>The definition of “product-market fit” I follow is that you have to race to keep up with all the demand for what you're offering.</span>`;
const aboutCompanySideNote = `<span>(we make software that supports people during addiction recovery)</span>`;
const islanderRootProblemSideNote = `<span>For Feynman’s islanders it would be something like their lack of knowledge about modern technology and global trade.</span>`;
const intellectualBlindnessSideNote = `<span>Unfathomable political views started making more sense once I realized this.</span>`;
const peerSupporterDefinitionSideNote = `<span>(people who are in long-term recovery from addiction and have completed training to use their experience to help other people recover)</span>`;
const peerSupportersPMFSideNote = `<span>A major reason why programs like peer coaching have been able to reach product-market fit is because the peer coaches have such an extreme depth of understanding of addiction and recovery.</span>`;
const peerSupporterFundingSideNote = `<span>(and they’ve only grown as much as they have because of the tireless efforts of people who know how important they are)</span>`;


export const PublicHealth = ({}) => {
  return (
    <Grid className="centered-content">
      <div className="container">
        <p className="essay-title">
          Cargo Cults, Supply Chains, Personal Failure, and Public Health
        </p>
        <p className="essay-date">
          12/31/23
        </p>
        <p className="essay-section-heading">
          Cargo Cults
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Feynman’s <a href="https://calteches.library.caltech.edu/51/2/CargoCult.htm" rel="noopener noreferrer" target="_blank">cargo cult metaphor</a> describes people who diligently “follow all the apparent precepts and forms of scientific investigation” but don’t deliver anything of value because they aren’t intellectually rigorous:
          </span>
          <p className="quote-block">
            In the South Seas there is a Cargo Cult of people. During the war they saw airplanes land with lots of good materials, and they want the same thing to happen now. So they’ve arranged to make things like runways, to put fires along the sides of the runways, to make a wooden hut for a man to sit in, with two wooden pieces on his head like headphones and bars of bamboo sticking out like antennas – he’s the controller – and they wait for the airplanes to land. They’re doing everything right. The form is perfect. It looks exactly the way it looked before. But it doesn’t work. No airplanes land.
          </p>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            When people apply this metaphor to startups, they’re referring to companies that take superficial steps to building a company – raising money, hiring, using trendy technology – without solving an important problem for customers. Just like the South Seas cargo cults do have pieces that help planes land (they have a runway, they have people working at the “airport”, they’re actively building), many of the activities of “cargo cult startups” can be important for creating a successful company, but those efforts are empty if they’re not rooted in making something valuable for customers.
          </span>
        </p>
        <p className="essay-section-heading">
          Supply Chains
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            The problem with this metaphor is that it sets the bar too low. Most companies make an honest effort at solving an important problem for customers and therefore aren’t cargo cult startups, but most companies still fail. The metaphor needs to be expanded to warn of cargo-culting at each small step of building a company, so that you end up with a series of potential cargo cults. Having the best airport in the world (resolving the cargo cult Feynman describes) still wouldn’t get supplies delivered to the South Seas islanders. They also need airplanes, pilots, a trading partner with its own airport, the cargo, communications infrastructure, and more. It’s not enough to not be a cargo cult, you need a whole supply chain, and if any part of the chain is broken then the cargo won’t get delivered. There are so many ways for startups to have the appearance of doing a certain step correctly but still missing the point. Maybe you have a team with the right roles but people aren’t actually good at what they do. Or you schedule calls with customers but don’t ask questions and listen carefully, to solve the right problem and iterate from the initial idea. Or you raise funding but aren’t careful about allocating it effectively. If you think the only hurdle to clear is not building the startup equivalent of wooden control towers and bamboo antennas, then you’ll work earnestly on renovating your beautiful, modern, fully-functional airport, wondering why planes don’t land.
          </span>
        </p>
        <p className="essay-section-heading">
          Personal Failure
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Most companies clear this next hurdle as well. They have not only an airport, but also planes, pilots, cargo, and trading partners. But if your company has been up and running for a while and you still haven’t reached product-market fit, then something in your supply chain is missing or broken. <SideNote note={pmfDefinitionSideNote} isCaption={false} /> Once you admit that something’s broken and identify what it might be, you can start peeling away the layers to find the root cause (be careful though, because to paraphrase <a href="https://en.wikipedia.org/wiki/Hickam%27s_dictum" rel="noopener noreferrer" target="_blank">Hickam</a>, a supply chain can be broken in as many places as it damn well pleases). In my company’s case <SideNote note={aboutCompanySideNote} isCaption={false} />, the problems at the surface were low sales and awful growth, so you could say we were cargo-culting the sales process. However, just like if you were to show Feynman’s islanders a blueprint for a real airport they wouldn’t be any closer to having a functioning one, simply trying to improve our sales tactics didn’t even begin to solve our problems. You have to drill down multiple levels to get to the core of what’s causing the failure. <SideNote note={islanderRootProblemSideNote} isCaption={false} />
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Digging deeper, my company losing deals was caused by addressing customer needs with limited depth…which was caused by trying to make the product do too many things for too many people…which was caused by my inability to be realistic about how much it’s possible to get done in a day…which was caused, finally, by my deep-seated, unexamined preconception about how broad our impact needed to be from the outset. I used to think the expression, “It is difficult to get a man to understand something, when his salary depends upon his not understanding it” means that people are <u>unwilling</u> to admit to something against their self-interest. I now believe, based on the times I’ve caught myself being intellectually dishonest, that people are <u>unable</u> to see an uncomfortable truth. <SideNote note={intellectualBlindnessSideNote} isCaption={false} /> It might be because of confirmation bias, self-interest, tribalism, animosity, laziness, ego/insecurity, idealism, ideology, incompetence, ignorance, identity, or any other reason for wanting the truth to be a certain way. “Intellectual dishonesty” implies intent; this is intellectual blindness. It’s why it’s so hard to see what fundamental problem in you or your company needs to be fixed. When I say that “my inability to be realistic about how much it’s possible to get done in a day” was caused by my “preconception about how broad our impact needed to be from the outset”, I mean that some part of me couldn’t accept a product that only helped in a small way at first, and this need for a larger scope blinded me to how disastrous it was to build so much. I was unable to see that my company was moving forward at an unacceptable pace because I was unable to see that I was taking way too much on.
          </span>
        </p>
        <p className="essay-section-heading">
          Public Health
        </p>
        <p className="essay-subsection-heading">
          These problems are so difficult
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Lack of focus is a classic mistake for startups, just not to the degree that I took it to. Most other companies pick a single, specific business problem and stick to it. It’s in public health efforts  that I noticed a striking similarity to my own mistakes. While amazing work is being done every day, overall it can feel like bailing water out of a boat with a sieve. A <a href="https://www.penguinrandomhouse.com/books/594500/rough-sleepers-by-tracy-kidder/" rel="noopener noreferrer" target="_blank">profile</a> of <a href="https://www.bhchp.org/institute-team/jim-oconnell/" rel="noopener noreferrer" target="_blank">Jim O’Connell</a>, who has worked in homeless services for 40 years as founding physician of Boston Health Care for the Homeless Program, includes the haunting sentence, “He felt as though he was seeing his own past through them, watching them realize, as he had, that they could do a lot for patients from day to day, but not much to fix the real causes of their misery.” 
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            The problems in public health work are backbreaking even in the best case scenario, and factors outside of people’s control make it so that it’s never the best case scenario. People join the public health field to address important problems, and there are so many of them, and each one is so vast, and each one is so complex, and they're all interconnected. For anything that seems like a single problem, there are multiple distinct causes that lead to a coil of problems. The coil for the U.S. addiction crisis includes antiquated regulations, a broken healthcare system, drug trafficking, workforce shortages, and an inadequate social safety net. If you look closely those threads are made up of braids of even thinner threads, and each of those thinner threads is its own intractable problem to solve.
          </span>
        </p>
        <div className="image-container">
          <img src={wireCoil} className="essay-image" />
          <p className="image-caption">
            <span>
              The addiction crisis
            </span>
          </p>
        </div>
        <p className="essay-subsection-heading">
          The necessity of a painfully narrow focus
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            It’s natural to think that achieving a widespread improvement in public health is incompatible with keeping a narrow focus – if you want to help as many people as possible as much as possible, it would seem obviously wrong to limit your scope. But the products, discoveries, and movements that have had the biggest impact show us that it’s important to start small, then evolve. Consider Apple’s 1976 hobbyist kit for building a personal computer, Katalin Karikó’s 1990s research on mRNA, or Gandhi’s 1919 push to boycott British goods. The massive impacts these efforts ultimately had masks how meager they were in the beginning. 
          </span>
        </p>
        <p className="essay-subsection-subheading">
          Having a deep and lasting impact
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            The fact that public health work is so difficult and there’s so much outside of people’s control makes it even more important to focus as narrowly as possible. Helping just one single person make a lasting change in one small aspect of their life is hard enough, whether it’s adherence to medications for opioid use disorder, or using less salt in their food, or reading for 10 minutes a day after school. If we’re hoping for public health efforts to be effective at the scale of a community, not to mention a city or a state, the change to aim for needs to be even smaller than examples like medication adherence, salt reduction, and daily reading.
          </span>
        </p>
        <p className="essay-subsection-subheading">
          Picking the right problem and approach to that problem
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Aiming even smaller requires first finding a tiny subset of the problem to focus on that has an impact on the broader problem you’re hoping to address, and then finding an approach that successfully addresses the tiny sub-sub-problem. Just like having any deep impact requires staying narrow, figuring those two pieces out also requires staying narrow because there are so many possible sub-sub-problems to pick from and so many possible approaches to those sub-sub-problems.
          </span>
          <ul className="arrow-list">
            <li className="arrow-list-item">
              <span className="arrow-list-arrow essay-text">➔</span> 
              <span className="essay-text">For a problem within addiction, you could try to increase uptake of medications for opioid use disorder (MOUD). Within that, you could pick from the sub-sub-problems of regulations that make it harder for people to access MOUD, or the shortage of primary care providers making it available to patients, or most addiction treatment centers not offering it as an option for their treatment, or people not being able to get a prescription when they’re in the emergency room after an overdose, or stigma against addiction medicines in the recovery community, or people not adhering to MOUD once it’s prescribed. As an example from another direction, there were hundreds of other biotechnology researchers working at the same time as Katalin Karikó, with just as narrow of a scope, who were just as dedicated, who were working in adjacent fields, but who chose a different sub-sub-problem to focus on, which may have been valuable but didn’t have nearly the impact of Karikó’s work.</span> 
            </li>
            <li className="arrow-list-item">
              <span className="arrow-list-arrow essay-text">➔</span> 
              <span className="essay-text">And for an approach for addressing the problem of insufficient access to MOUD, let’s say you choose to tackle people not adhering to MOUD once it’s prescribed. You could make an app that sends medication reminders, or create a text messaging service that sends reminders, or hire peer coaches to manually send reminders, or create educational content that dispels myths about the medicine and highlights the importance of it, or train prescribers in motivational interviewing for keeping up with MOUD, or any number of other approaches. To take Apple’s example of finding the right solution to a sub-sub-problem, Steve Wozniak could have made any kind of hardware product, but he built what turned into the Apple I because it’s the computer he wanted for himself.</span> 
            </li>
          </ul>
          <span className="essay-text">
            Karikó’s and Wozniak’s cases are reminders that the “right” sub-sub-problem to take on is the one that you’re in a position to address and most pulled to work on, as opposed to going after something only because it seems important. There are almost always multiple sub-sub-problems and multiple approaches within each sub-sub-problem that are critical for people to work on. Even if one sub-sub-problem might be more urgent than another one, or one solution is more effective than another one, it doesn’t mean everybody should work only on that. Hypothetically, even if there were data showing that getting more treatment centers to offer MOUD saves more lives than getting more people to adhere to MOUD once prescribed, it would still be important for people to work on getting more people to adhere to MOUD. And similarly, even if there were data showing that app reminders are more effective than motivational interviewing for MOUD adherence, it would still be important for people to work on helping prescribers with motivational interviewing. It’s a matter of being flexible about which path to take that’s most effective in reaching your goals, given the domain of problems and solutions that make sense for you.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            The trouble is that there’s so much complexity to account for when picking sub-sub-problems and figuring out solutions. Visualizing my own understanding of the world as a map to navigate by, it <i>feels</i> like it’s the equivalent of Google Earth – a highly-detailed, 3D scan of exactly how things are – but I’ve learned it’s more like a picture drawn by an eight-year-old on printer paper with Crayola markers. Our brains can only handle so much information, and there’s only so much time in the day to gather that information, so the wider your scope the less it’s possible to have an accurate and comprehensive understanding of what you’re trying to make a difference on, which means the less it’s possible to arrive at the right focus for your work. Asking the eight-year-old mapmaker in my head to draw me a single city block gets me more useful results than asking for a neighborhood or a whole town. I’m reminded of this lesson every time I show people a new app feature, confident about how perfect it is, then learn all the factors I didn’t consider. Appreciating how deep any problem goes and how much complexity there is around it makes me recognize the need to try multiple approaches to find the right one.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Portland’s Measure 110 demonstrated this idea recently. The policy of issuing $100 citations for drug use, which can then be waived by calling a hotline and going through a health screening and even being offered peer support and housing, sounded extremely promising to me. But after over a year, <a href="https://sos.oregon.gov/audits/Documents/2023-03.pdf" rel="noopener noreferrer" target="_blank">the hotline received only 119 calls</a> and of those only 27 people expressed interest in treatment resources – that’s not how many people accessed those resources, it’s how many merely expressed interest. As Mingus Mapps, a Democratic City Council Commissioner who voted for the policy, <a href="https://www.csmonitor.com/layout/set/amphtml/USA/Society/2023/0905/Oregon-s-bold-drug-policy-isn-t-working-yet" rel="noopener noreferrer" target="_blank">put it</a>, “It’s clear that the assumptions behind Measure 110 were fundamentally flawed.” The results could mean that the policy is the wrong approach entirely and needs to be abandoned, or it could mean that the direction is right but it needs improvements to be effective. Either way, even though the policy wasn’t effective, it was invaluable for the evidence it provided about assumptions some people (like me) hold that are flawed. You can be right about a change that needs to happen – for example advocating for increased access to harm reduction resources – but be wrong about how to put that idea into practice in a helpful way. Because any initial approach will need to be improved based on the results you see, and because the more you take on the harder it is to know what direction to go in from those results, the way to arrive at the right approach is by starting off with as narrow of a scope as possible.
          </span>
        </p>
        <p className="essay-subsection-subheading">
          Making sure you’re on the right track
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            There of course has to be a way to decide what “good” results even are. And there has to be some objective, short-term metric for that evaluation, because the hardest part of all of this is being honest with yourself about whether you’re on the right track or need to change something – you won’t improve your approach if you don’t think it needs to change. This is yet another area where public health work is extra challenging: outcomes for your goals can take months or years to emerge, data on those long-term goals are often hard to pin down, and defining meaningful short-term metrics is even more difficult. Those short-term metrics are treacherous, because they give you a second opportunity to not be honest with yourself. Does increasing the number of conversations per week with a program participant increase their likelihood of recovery? I personally believe it does, but I don’t know if there’s good data on that. Even if we did know that it leads to better recovery outcomes, is that the most important short-term metric to evaluate performance by and guide the focus of the program? The need for a small scope continues to bedevil us here, because the more you take on, the more variables there are impacting your results, and the harder it is to know if the short-term metric you’ve picked is the right one to follow.
          </span>
        </p>
        <p className="essay-subsection-subheading">
          Implementing something there’s demand for
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Demand for what you’re offering is also a precondition for effectiveness, working in tandem with the short-term metric you choose. If you have a program with a 100% success rate when people join, but you can’t get anyone to join, then it’s completely ineffective in practice. I’m not talking about “demand” in the sense of the goals of your program being worthwhile and needed, it’s about whether people will go out of their way to be a part of it. Demand is more complicated in public health than selling business software, because in certain cases people are conflicted about a resource, with a certain part of them wanting it and another part of them pulling them away from it. Some amazing addiction programs, like <a href="https://www.fcsok.org/services/women-in-recovery/" rel="noopener noreferrer" target="_blank">Women in Recovery</a> in Oklahoma, have participants whose addiction makes them <a href="https://www.nytimes.com/2024/02/14/opinion/drug-addiction-recovery.html" rel="noopener noreferrer" target="_blank">“resentful” toward the program</a> to the point of running away, before ultimately returning and being extremely grateful for it. Still, Women in Recovery meets the baseline level of demand where people are willing to start and continue to engage, which isn’t a given – a worse program would push everyone to run away, or have them going through the motions without getting anything out of it. In other cases people aren’t conflicted about a resource but other barriers get in the way, like transportation. Public health programs are often squeezed by funding constraints so that even when there would be a way to address a factor that’s reducing demand (e.g. offering transportation vouchers), they’re not able to because the funding isn’t there. Making something that there’s demand for requires accounting for all the factors that could get in the way of people accessing what you’re offering.
          </span>
        </p>
        <p className="essay-subsection-subheading">
          Growing the impact to help more people
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Staying narrow is just as important as you grow from demand, for the same reasons it was so important when first starting off. At every stage of growth there is a storm of new factors to consider because of the different context you’re entering into and the differences in making something work at a larger scale. When you grow step by step, it’s possible to make the course corrections for these new factors, whereas if you make too big of a leap in the number of people you’re serving, it’s more likely for there to be too many changes to tackle for the basic operational function, for program efficacy, and for sustaining demand. Any extra bit of complexity in your scope makes maintaining success while growing more difficult. Instead of switching on an effort to implement public health programs at scale once they have promising results, what if public health programs had to reach scale by growing to it? What if the standard public health efforts were expected to reach was product-market fit?
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Having a deep and lasting impact, picking the right sub-sub-problem to address, figuring out the right approach for that sub-sub-problem, making sure you’re on the right track, implementing something there’s demand for, growing the impact to help more people. All of these pieces, each of which is necessary for improving any aspect of public health at scale, can’t be achieved without maintaining a scope so narrow that it makes you constantly feel like you’re not doing enough, because the remedy to the external complexity that you can’t control is internal simplicity for what you can control. I used to ask, “how could I take on such a limited scope given that the problem is so big?” but now remind myself, “how could I not take on such a limited scope given that the problem is so big?”
          </span>
        </p>
        <p className="essay-subsection-heading">
          What gets in the way
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            There are many existing efforts that have some pieces of what’s needed to start small and grow, but funding and other barriers prevent almost all of them from following that principle.
          </span>
          <ul className="arrow-list">
            <li className="arrow-list-item">
              <span className="arrow-list-arrow essay-text">➔</span> 
              <span className="essay-text">I would consider certified peer professionals <SideNote note={peerSupporterDefinitionSideNote} isCaption={false} />as having product-market fit in the addiction recovery space, because of how effective they are and how much demand for them there is. <SideNote note={peerSupportersPMFSideNote} isCaption={false} />But the growth of peer support programs has been sluggish due to being underfunded <SideNote note={peerSupporterFundingSideNote} isCaption={false} />, and peer professionals take on way more than they should have to…because other critical narrow focuses (transportation, care coordination, therapy, the list goes on and on) are also so underfunded.</span> 
            </li>
            <li className="arrow-list-item">
              <span className="arrow-list-arrow essay-text">➔</span> 
              <span className="essay-text">There’s a lot of promising work in all kinds of research labs that does a great job of picking a tiny piece of a problem to see what helps, and a field called <a href="https://en.wikipedia.org/wiki/Implementation_research" rel="noopener noreferrer" target="_blank">implementation science</a> has emerged for studying how to apply research to the real world. The problem is that implementation science continues to be treated as a separate effort from standard research. Implementation science practices should be part of any research, because if you’re not doing everything you can from the very beginning to test how your intervention works in the real world, then you’re not getting accurate results – to oversimplify a little, the implementation is the intervention. The other problem with how people treat promising research is that they expect to be able to go from success in a well-run study (implementation science included) to scaling up their intervention, instead of allowing the intervention to grow from its initial size, learning and adjusting along the way, to ultimately reach scale. As <a href="https://en.wikipedia.org/wiki/John_A._List" rel="noopener noreferrer" target="_blank">John List</a>, an economist who studies how to scale research, <a href="https://freakonomics.com/podcast/policymaking-is-not-a-science-yet-ep-405/" rel="noopener noreferrer" target="_blank">has said</a> on this topic, “When you step back and look at the amount of policies that we put in place that don’t work, it’s just a travesty.”</span> 
            </li>
            <li className="arrow-list-item">
              <span className="arrow-list-arrow essay-text">➔</span> 
              <span className="essay-text">Some organizations do take on a relatively narrow focus (although they still usually have to take on too much, because of how much there is to address in any domain), but then they have to coordinate with other organizations, which could theoretically be easy but is almost always slow and frustrating. It has always boggled my mind that after decades of addiction treatment, organizations focused on detox don’t reliably have partnerships with organizations focused on treatment so that there’s a guaranteed transition if the person wants it. Organizations are often pushed towards expanding their scope due to the hurdle-riddled task of coordination, because if someone comes to an organization with “Need A” and the organization is focused only on “Need B”, then when the organization can’t easily and immediately give a warm handoff to somewhere else that helps with “Need A” it feels like the organization is dropping the ball.</span> 
            </li>
            <li className="arrow-list-item">
              <span className="arrow-list-arrow essay-text">➔</span> 
              <span className="essay-text">But when that happens, it leads organizations to take on multiple aspects of a massive problem. Before Houston started coordinating across organizations, homeless service providers like SEARCH <a href="https://www.nytimes.com/2022/06/14/headway/houston-homeless-people.html" rel="noopener noreferrer" target="_blank">were</a> “trying to do everything: outreach, case management, child services, employment training, paying rent to landlords to house clients.” The services are easily connected under one roof, solving the problem in the previous point, but that means the organization has to spread itself thin and can’t go deep in any one area.</span> 
            </li>
          </ul>
        </p>
        <div className="image-container">
          <img src={frayedTangles} className="essay-image" />
          <p className="image-caption">
            <span>
              The funding to combat the addiction crisis
            </span>
          </p>
        </div>
        <p className="essay-subsection-heading">
          Change is possible
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            For an organization to be able to focus all their attention on a sub-sub-problem, gradually reaching more people and increasing their efficacy within that narrow area, there has to be close collaboration across organizations, and funding has to increase to support the growth of successful efforts. Centering municipal operations around focus, collaboration, and growth in this way is already happening. Houston made that shift in their efforts to reduce homelessness, resulting in <a href="https://www.nytimes.com/2022/06/14/headway/houston-homeless-people.html" rel="noopener noreferrer" target="_blank">more than twice the reduction</a> as other cities from 2010 to 2020. Annise Parker, Houston’s mayor from 2010 to 2016, said, “‘Different organizations were all working in their own lanes, according to their own rules and procedures, doing what they wanted to do.’” That’s the context that SEARCH, the homeless services provider mentioned above, was working in. Once organizations started working together, SEARCH “could focus on case management, leaving job training, child care and other services to fellow continuum members. That, in turn, allowed it to avoid financial collapse and to hire more case managers, a critical need.” The effort also started small and grew: “At first, the goal was to house 100 homeless veterans in 100 days, and after that was achieved, 300 more in another 100 days.”
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            With an example like Houston, it’s not so naive to start imagining the kinds of small efforts that could grow to be hugely impactful. What would the “mRNA research of homelessness” look like? What could address a tiny problem with extreme depth, then steer its way through iterations and incremental growth to radically improve the status quo? Maybe more importantly, what policy changes need to be made before that could happen? Remember, there will be different solutions for different sectors of the work, with each one alongside and strengthening the others – an mRNA vaccine would be worthless without collaborating with other organizations that scaled up their own narrow focus, from manufacturing plants to shipping networks to hospital systems. In a mayor’s office, the “mRNA research of homelessness” could be requiring two key organizations to work together. In a hospital, it could be hiring a peer supporter whose only job is to be there for one person whenever that person needed or wanted it. In an advocacy nonprofit, it could be enlisting a particularly deft lobbyist to listen one-on-one to NIMBYists’ concerns and shepherd them toward supporting new housing. But first of all I’m not the right person to identify the sub-sub-problems and their possible solutions, and second of all even the most qualified person would still be wrong in their initial ideas and would need to put something out into the world then adapt from what they learn.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            My goal is to create a more grounded, optimistic view of public health challenges, so that we have more realistic expectations for ourselves and each other. Whereas I only have myself to blame for spreading my company too thin, in traditional public health work there are often stifling external pressures, which means it will require many people and organizations changing at once. It’s not easy. What I’m describing means being <i>more</i> effective and <i>less</i> overwhelmed, and the tradeoff is that it’s so psychologically wearisome to seek out your blindspots instead of staying in the comfort of not changing anything, <i>even if the current way of doing things is more time and effort</i>. I’m constantly crashing into my own shortcomings that I didn’t know existed, like someone walking into a glass door. It’s painful when I notice how wrong I was about something and how long I went through life being wrong about it, but that ache quickly fades and what’s left is relieved excitement.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            I didn’t expect how empowering it would be to look directly at my failure. Despite all the evidence right in front of me every single day for years, my intellectual blindness made me unable to see that I was failing, and so before acknowledging it I was trapped in a broken way of operating, making little progress. By recognizing that I was failing, I finally had the space to see what was wrong. At a certain point I was able to ask: how can I have spent so long trying so hard and still be failing? The answer is that I was cargo-culting an important enough part of the supply chain (having a realistic view of how much to take on – doesn’t get much more fundamental than that) that it didn’t matter how well we were doing everything else. 
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            I don’t want to believe that the current pace of progress for public health is the pace it always has to be. Imagine how much better the world would be if we had a program that reached product-market fit for adults working through trauma, or for kids loving to spend more time outdoors. One of the great strengths of people working in public health is their patience. I’ve realized that my patience for reaching long-term goals seeped into my short-term goals, enabling slower progress. We need to keep our patience for the long-term goals while being impatient for the small milestones that get us there (and being honest with ourselves about whether the milestones we’ve chosen are truly getting us there). By starting tiny and steadily adjusting based on what works, we can find a path to deep and widespread impact. 
          </span>
        </p>
      </div>
    </Grid>
  )
}
