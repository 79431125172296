import './App.css';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Home } from "./pages";
import { Keto, Mindfulness, PublicHealth, OldPublicHealthURL, Crusty, CrustyCondensed, OldCrustyURL, OldCrustyCondensedURL } from "./writing";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* Keto and redirects */}
      <Route path="/writing/keto" element={<Keto />} />
      <Route path="/writing/case-for-normalizing-keto" element={<Navigate to="/writing/keto" />} />
      <Route path="/writing/making-mindfulness-your-default" element={<Mindfulness />} />
      <Route path="/writing/personal-failure-public-health" element={<PublicHealth />} />
      <Route path="/writing/cargo-cults-supply-chains-personal-failure-public-health" element={<OldPublicHealthURL />} />
      <Route path="/writing/woke-vs-crusty" element={<Crusty />} />
      <Route path="/writing/woke-vs-crusty-1minute" element={<CrustyCondensed />} />
      <Route path="/writing/woke-vs-altwoke" element={<OldCrustyURL />} />
      <Route path="/writing/woke-vs-crusty-1minute" element={<CrustyCondensed />} />
      <Route path="/writing/woke-vs-crusty-condensed" element={<OldCrustyCondensedURL />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
