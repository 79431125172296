import { useEffect } from 'react';
import { Grid } from "@mui/material";
import SideNote from "../components/SideNote";
import SimpleList from "../components/SimpleList";
import '../App.css';

// Data
import { disagreementReactionExampleSideNote, allPartiesCrustySideNote, jdVanceResearchSideNote, antiTransCrustySideNote, transRightsSideNote, takeiTweetSideNote, moreTrumpCrustyExamplesSideNote, laptopMisinformationSideNote, respectingChoiceSideNote, peopleIAgreeWithSideNote, understandReluctanceSideNote, previewToTrumpExamples, trumpExamples, notCriticizingSupportersSideNote } from '../data/CrustyData';

// Images
import otisImage from '../images/Crusty/otis.jpg';
import takeiTweet from '../images/Crusty/crusty-tweet.png';
import positiveTweet from '../images/Crusty/positive-tweet.png';
import nraLobby from '../images/Crusty/nra-lobby.png';


export const CrustyCondensed = ({}) => {
  useEffect(() => {
    document.title = 'Woke vs. Crusty (one-minute version)';
  }, []);
  return (
    <Grid className="centered-content">
      <div className="container">
        <p className="essay-title">
          Woke vs. Crusty (one-minute version)
        </p>
        <p className="essay-date">
          10/18/24
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">Being “crusty” means being <u>closed-minded</u> about seeing the world in a different way, and <u>negative</u> to people who see the world in a different way.</span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">I have plenty of frustrations about Harris, and she is crusty on some <a target="_blank" rel="noopener noreferrer" href="https://kamalaharris.com/issues/">policies</a>, but she mostly acts independently from the crustiest part of the left-wing. <SideNote note={understandReluctanceSideNote} isCaption={false} /></span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">Trump has created himself in the image of the crustiest part of the right-wing: the headline of his website says, “<a target="_blank" rel="noopener noreferrer" href="https://www.donaldjtrump.com/">They’re not after me, they’re after you</a>” and he calls liberals “<a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=9DauQjmpqZM">the enemy from within</a>”. Trump’s crustiness makes him act on his nice-sounding <a target="_blank" rel="noopener noreferrer" href="https://www.donaldjtrump.com/issues">policy ideas</a> in ways that make people’s lives worse.</span>
          <SimpleList listItems={previewToTrumpExamples}/>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">That’s not far-left fearmongering, those are things he’s already done.</span>
          <SimpleList listItems={trumpExamples}/>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            <b>You can’t separate someone’s character from their policies, because their character determines how the policies are put into practice.</b>
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            The crusty tactic that bothers me most is when leaders turn Americans against each other, manipulating us so they can get power – it creates a downward spiral of negativity, which weakens the country and prevents positive change. Over and over again, Trump has shown us that closed-mindedness and negativity come first. <SideNote note={moreTrumpCrustyExamplesSideNote} isCaption={false} />
          </span>
        </p>
        <p className="essay-text" style={{marginTop:'70px'}}>
          <a href="/writing/woke-vs-crusty">(Click here to view the full version)</a>
        </p>
      </div>
    </Grid>
  )
}
