import { useState } from 'react';

interface sideNoteProps {
  note: string;
  isCaption: boolean;
}

const SideNote: React.FC<sideNoteProps> = ({ note, isCaption }) => {
  const [showText, setShowText] = useState(false);

  const handleClick = () => {
    setShowText(!showText);
  };

  return (
    <span>
      {!showText?
        <span>
          <text
            onClick={handleClick}
            className={isCaption===true?"info-button image-caption":"info-button info-button-closed"}
          >
            ⓘ
          </text>
        </span>     
      :
        <span>
          <text
            onClick={handleClick}
            className={isCaption===true?"info-button image-caption":"info-button info-button-open"}
          >
          ⓘ
          </text>
          <text
            onClick={handleClick}
            className={isCaption===true?"info-button side-note-text image-caption":"info-button side-note-text info-button-open"}
            dangerouslySetInnerHTML={{ __html: note }}
          >
          </text>
          <text
            onClick={handleClick}
            className={isCaption==true?"info-button image-caption":"info-button info-button-open"}
          >
          ⓘ
          </text>
        </span>
      }
    </span>
  );
};

export default SideNote;